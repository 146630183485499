import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Problem from "./components/Problem";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="problems/:id" element={<Problem />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
