import React from "react";
import Typography from "@mui/material/Typography";

const Header = () => {
  return (
    <Typography
      variant="h1"
      sx={{
        color: "white",
        textAlign: "center",
        padding: 5,
        backgroundColor: "primary.dark",
      }}
    >
      Hello World
    </Typography>
  );
};

export default Header;
