import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const axios = require("axios");

const Problems = () => {
  const [problems, setProblems] = useState();

  // TODO: error handling
  // TODO: throttling
  // TODO: custom URL
  useEffect(() => {
    const getProblems = async () => {
      setProblems(
        await axios.get(
          "https://dxpcdztzb9.execute-api.us-west-2.amazonaws.com/test/problems"
        )
      );
    };

    getProblems();
  }, []);

  if (!problems?.data) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: 3,
        textAlign: "center",
      }}
    >
      {problems.data.map((id) => (
        <Button
          key={`${id}`}
          variant="outlined"
          href={`problems/${id}`}
          sx={{
            width: 300,
            margin: 3,
          }}
        >
          {`${id}`}
        </Button>
      ))}
    </Box>
  );
};

export default Problems;
