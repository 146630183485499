import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ReactMarkdown from "react-markdown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const axios = require("axios");

const Problem = () => {
  const { id } = useParams();
  const [content, setContent] = useState();

  useEffect(() => {
    const getContent = async () => {
      const res = await axios.get(
        `https://ytiq294uj8.execute-api.us-west-2.amazonaws.com/test/problem?id=${id}`
      );

      setContent(res.data);
    };

    getContent();
  }, [id]);

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          color: "white",
          backgroundColor: "primary.dark",
          padding: 5,
        }}
      >
        {id}
      </Typography>
      <Box
        sx={{
          padding: 5,
        }}
      >
        <ReactMarkdown children={content} />
      </Box>
      <Button
        variant="outlined"
        href="/"
        sx={{
          width: 100,
          margin: 5,
        }}
      >
        Go Back
      </Button>
    </>
  );
};

export default Problem;
